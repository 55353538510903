import { useEffect, useState } from 'react';
import Loading from '../../layout/loading/Loading';
import Tab from '../../tab/Tab';
import styles from './SendingEmail.module.css';
import { AiFillFilePdf } from 'react-icons/ai';
import { checkJwt } from '../../../helpers/Jwt-helper';
import { formatPhoneNumber, formatDocument, formatDateTime } from '../../../helpers/Format';
import { useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import stylesModal from '../tableMonitoringModal/ModalMonitoring.module.css';

const StatusType = {
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

const getStatusDescription = (status) => {
    switch (status) {
        case StatusType.PENDING:
            return 'Pendente';
        case StatusType.SUCCESS:
            return 'Enviado';
        case StatusType.ERROR:
            return 'Erro';
        default:
            return 'Pendente';
    }
};

const SendingEmail = ({ data, proposalData}) => {
    const [documentsBbc, setDocumentsBbc] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function viewPDF(id) {
        window.open(`../viewpdf/${id}/INC`, '_blank', 'noopener,noreferrer');
    }

    const checkTokenViewPDF = (id) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                viewPDF(id);
            }
        })
    }

    async function getDocuments() {
        try {
            setLoading(true);
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_DOCUMENT_EMAIL}${data?.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'
                },
            }).then((response) => {
                setDocumentsBbc(response.data);
            }).catch((error) => {
                setDocumentsBbc(null)
                console.log(error)
            })
        } catch (error) {
            setDocumentsBbc(null)
            console.log(error)
            console.log(error.response)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getDocuments();
    }, [])

    const viewDocuments = () => {
        if (documentsBbc === null) {
            return (
                <button disabled className={styles.disabled}>Não há informações sobre o envio de documentos</button>
            );
        } else {
            return (
                <div className={`${stylesModal.content} ${styles.actionButtonsPDF}`}>
                    <h3>Titular</h3>
                    {documentsBbc.holders?.map((holder, index) => (
                        <div key={"holder" + index}>
                            <div className={styles.documentsContainer}>
                                <div className={styles.rowsInformation}>
                                    <div>
                                        <p>Nome</p>
                                        <strong>{holder.name}</strong>
                                    </div>
                                    <div>
                                        <p>CPF</p>
                                        <strong>{formatDocument(holder.document)}</strong>
                                    </div>
                                    <div>
                                        <p>Telefone</p>
                                        <strong>{formatPhoneNumber(holder.phone)}</strong>
                                    </div>
                                    <div>
                                        <p>E-mail</p>
                                        <strong>{holder.email}</strong>
                                    </div>
                                    <div>
                                        <p>Data de envio</p>
                                        <strong>{formatDateTime(holder.date)}</strong>
                                    </div>
                                    <div>
                                        <p>Status</p>
                                        <strong>{getStatusDescription(holder.status)}</strong>
                                    </div>
                                    <div>
                                        <p>Documentos</p>
                                    </div>
                                </div>
                                <div className={styles.documents}>                                    
                                    {holder.files.map((file) => (
                                        <button key={file.id} onClick={() => checkTokenViewPDF(file.id)}><AiFillFilePdf /> {file.documentType.description}</button>
                                    ))}
                                </div>
                            </div>
                            <hr />
                        </div>
                    ))}
                    <h3>Avalista</h3>
                    {documentsBbc.guarantors?.map((guarantor, index) => (
                        <div key={"guarantor" + index}>                            
                            <div className={styles.documentsContainer}>
                                <div className={styles.rowsInformation}>
                                    <div>
                                        <p>Nome</p>
                                        <strong>{guarantor.name}</strong>
                                    </div>
                                    <div>
                                        <p>CPF</p>
                                        <strong>{formatDocument(guarantor.document)}</strong>
                                    </div>
                                    <div>
                                        <p>Telefone</p>
                                        <strong>{formatPhoneNumber(guarantor.phone)}</strong>
                                    </div>
                                    <div>
                                        <p>E-mail</p>
                                        <strong>{guarantor.email}</strong>
                                    </div>
                                    <div>
                                        <p>Data de envio</p>
                                        <strong>{formatDateTime(guarantor.date)}</strong>
                                    </div>
                                    <div>
                                        <p>Status</p>
                                        <strong>{getStatusDescription(guarantor.status)}</strong>
                                    </div>
                                    <div>
                                        <p>Documentos</p>
                                    </div>
                                </div>
                                <div className={styles.documents}>
                                    {guarantor.files.map((file) => (
                                        <button key={file.id} onClick={() => checkTokenViewPDF(file.id)}><AiFillFilePdf /> {file.documentType.description}</button>
                                    ))}
                                </div>
                            </div>
                            <hr />
                        </div>
                    ))}
                </div>
            );
        }
    }

    return (
        <div className={styles.sendingEmailContainer}>
            {loading && <Loading />}
            {!loading && (
                <Tab
                    tabsName={[
                        'Proposta',
                        'Documentos'
                    ]}
                    tabsInfo={[
                        <>{proposalData()}</>,
                        <>{viewDocuments()}</>
                    ]}
                />
            )}
        </div>
    )
}

export default SendingEmail