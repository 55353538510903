import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../layout/loading/Loading';
import styles from './TableShops.module.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { checkJwt } from '../../../helpers/Jwt-helper';
import api from '../../../services/api';

const TableShops = ({ object, handleCurrentPage, currentPage, updateData, removeLoading }) => {

    const navigate = useNavigate();
    const [currentData, setCurrentData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [filterValue, setFilterValue] = useState('')
    const [loading, setLoading] = useState(false);
    const handleOnChange = (e) => {
        updateShop(e.target.id);
    }
    const handleAllSelected = (visible) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                setFilterValue('');
                setLoading(true);
                api.patch(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_SETTINGS_SHOPS}`, {
                    "isVisible": visible
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    setCurrentData(response.data.object);
                    setOriginalData(response.data.object)
                    setLoading(false);
                    toast.success(`${response?.data?.message}`);
                }).catch((error) => {
                    console.log(error);
                    setLoading(false);
                    toast.error(`Deu erro! ${error?.response?.data?.message}`);
                })
            }
        })
    }

    const filter = () => {

        const newItems = originalData.filter((data) => data?.name?.toLowerCase()?.includes(filterValue.toLowerCase()))
        if (!filterValue) {
            setCurrentData(originalData)
            return;
        }
        setCurrentData(newItems);
    }
    const updateShop = async (id) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                api.patch(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_SETTINGS_SHOPS}/${id}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    const newItems = currentData.map((item) => {
                        if (item.id === response?.data?.object?.id) {
                            return {
                                ...item,
                                visible: response?.data?.object?.visible
                            }
                        }
                        return item;
                    });
                    originalData.forEach((item) => {
                        if (item.id === response?.data?.object?.id) {
                            item.visible = response?.data?.object?.visible
                        }
                    })
                    setCurrentData(newItems);
                    toast.success(`${response?.data?.message}`);
                }).catch((error) => {
                    console.log(error);
                    toast.error(`Deu erro! ${error?.response?.data?.message}`);
                })
            }
        })
    }
    useEffect(() => {
        setCurrentData(object);
        setOriginalData(object);
    }, [object])


    return (
        <div className={styles.divContainer}>
            <h2 className={styles.titleFilter}>Filtrar por loja: </h2>
            <div className={styles.actions}>
                <input type="text" value={filterValue || ''} onKeyUp={filter} onChange={(e) => setFilterValue(e.target.value)} />
                {!loading ? (
                    <>
                        {originalData.filter(x => x.visible === true).length === originalData.length ?
                            <button onClick={() => handleAllSelected(false)}>Desmarcar todos!</button> :
                            <button onClick={() => handleAllSelected(true)}>Marcar todos!</button>}
                    </>
                ) : <button disabled>Aguarde...</button>}
            </div>
            {!removeLoading && <Loading />}
            <table className={styles.tableShopsContainer}>
                <thead>
                    <tr>
                        <th>Assinatura digital</th>
                        <th>Loja</th>
                    </tr>
                </thead>
                <tbody>
                    {currentData && currentData?.length === 0 && (
                        <tr className={styles.noData}>
                            <td>Não há dados</td>
                        </tr>
                    )}
                    {currentData && currentData?.length > 0 && (
                        currentData.map((data, index) => (
                            <tr key={data?.id}>
                                <td>
                                    <strong>Assinatura digital</strong>
                                    <input type="checkbox" id={data?.id} name={data?.name} checked={data?.visible} onChange={(e) => handleOnChange(e)} />
                                </td>
                                <td>
                                    <strong>Loja</strong>
                                    {data?.name}
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default TableShops