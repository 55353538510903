import { useEffect } from "react";
import { useState } from "react";
import api from "../../../services/api";
import Container from "../../layout/container/Container";
import TableShops from "../../project/tableShops/TableShops";
import styles from './Shops.module.css';
const Shops = () => {
    const [removeLoading, setRemoveLoading] = useState(true);
    const [dataTable, setDataTable] = useState([])
    const [currentPage, setCurrentPage] = useState(0);

    const MINUTE_MS = process.env.REACT_APP_RELOAD_TABLE * 1000 * 60;
    const updateData = () => {
    }
    useEffect(() => {
        async function getShops() {
            try {
                setRemoveLoading(false);
                await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_SETTINGS_SHOPS}`, {
                    headers: {
                        "Content-Type": "application/json",
                        'Cache-Control': 'no-cache'

                    },
                }).then((response) => {
                    setDataTable(response.data.object);
                    setRemoveLoading(true);
                })
                    .catch((error) => {
                        setDataTable([])
                        console.log(error)
                        setRemoveLoading(true)
                    })
            } catch (error) {
                console.log(error)
                console.log(error.response)
            }
        }
        const interval = setInterval(() => {
            getShops();
        }, MINUTE_MS);

        getShops();
        return () => clearInterval(interval);
        // eslint-disable-next-line 
    }, [currentPage, MINUTE_MS])
    return (
        <div className={styles.shopsContainer}>
            <div className={`${styles.card} ${styles.column}`}  >
                <h2>Lojas</h2>
                <Container>
                    <TableShops object={dataTable} handleCurrentPage={setCurrentPage} currentPage={currentPage} updateData={updateData} removeLoading={removeLoading} />
                </Container>
            </div>
        </div>
    )
}

export default Shops