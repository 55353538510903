export function formatPhoneNumber(phoneNumber) {
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.length === 10) {
        return cleaned.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }

    if (cleaned.length === 11) {
        return cleaned.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2$3-$4');
    }

    return phoneNumber;
}

export function formatDocument(document) {
    const cleaned = document.replace(/\D/g, '');

    if (cleaned.length === 11) {
        return cleaned.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }

    if (cleaned.length === 14) {
        return cleaned.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }

    return document;
}

export function formatDateTime(dateTime) {
    const cleaned = dateTime.replace(/\D/g, '');

    if (cleaned.length === 14) {
        const date = cleaned.slice(0, 8);
        const time = cleaned.slice(8);
        const formattedDate = `${date.slice(6, 8)}/${date.slice(4, 6)}/${date.slice(0, 4)}`;
        const formattedTime = `${time.slice(0, 2)}:${time.slice(2, 4)}:${time.slice(4)}`;
        return `${formattedDate} - ${formattedTime}`;
    }

    return dateTime;
}