import { Routes, Route, Navigate } from "react-router-dom"
import Login from "../components/pages/login/Login";
import Monitoring from "../components/pages/monitoring/Monitoring";
import Home from "../components/pages/home/Home";
import PageNotFound from "../components/pages/notFound/PageNotFound";
import Unavailability from "../components/pages/unavailability/Unavailability";
import Shops from "../components/pages/shops/Shops";
import ViewPdf from "../components/project/viewPdf/ViewPdf";
import ViewImage from "../components/project/viewImage/ViewImage";
import PageForbidden from "../components/pages/forbidden/PageForbidden";


import Combat from '../components/pages/combat/Combat'
// useContext
import { useContext } from 'react';
import { RoleContext } from '../components/hooks/HookUseContext';
import Main from "../components/pages/incidents/screens/Main";

const Routers = () => {
  const { roleValue } = useContext(RoleContext);
  const isLoggedIn = localStorage?.getItem("token");
  if (isLoggedIn && !roleValue) return <p>Carregando...</p>;

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />( isLoggedIn ?
      {roleValue && (
        <>
          <Route path="/monitoring" element={roleValue?.includes("PROPOSAL_READ") ? <Monitoring /> : <Navigate to="/forbidden" />} />
          <Route path="/home" element={<Home />} />
          {
          /*
          <Route path="/insurancePlan" element={role?.current?.role?.role === "seguro_admin" ? <InsurancePlan /> : <Navigate to="/monitoring" />} />
          <Route path="/fromTo" element={role?.current?.role?.role === "seguro_admin" ? <FromTo /> : <Navigate to="/monitoring" />} />
          <Route path="/parameter" element={role?.current?.role?.role === "seguro_admin" ? <Parameter /> : <Navigate to="/monitoring" />} />
          <Route path="/product" element={role?.current?.role?.role === "seguro_admin" ? <Product /> : <Navigate to="/monitoring" />} />
          */}
          <Route path="/unavailability" element={roleValue?.includes("UNAVAILABILITY_READ") ? <Unavailability /> : <Navigate to="/monitoring" />} />
          <Route path="/shops" element={roleValue?.includes("STORE_READ") ? <Shops /> : <Navigate to="/monitoring" />} />
          <Route path="/combat" element={roleValue?.includes("COMBAT_READ") ? <Combat /> : <Navigate to="/monitoring" />} />
          <Route path={"/viewpdf/:id/:type/:dataId"} element={<ViewPdf />} />
          <Route path={"/viewpdf/:id/:type/"} element={<ViewPdf />} />
          <Route path={"/viewimage/:id"} element={<ViewImage />} />
          <Route path={"/forbidden"} element={<PageForbidden />} />
          <Route path={"/*"} element={<PageNotFound />} />
          <Route path={"/incidents"} element={<Main />} />
        </>
      )}
      : <Route path="*" element={<Navigate to="/login" />} />)
    </Routes>
  );
};

export default Routers;
