import React, { useContext, useState } from 'react';
import { RiAddCircleLine, RiIndeterminateCircleLine } from 'react-icons/ri';
import styles from './InteractiveListProposal.module.css';
import { useNavigate } from 'react-router-dom';
import InteractiveList from '../interactiveList/InteractiveList';
import api from '../../../../services/api';

const InteractiveListProposal = ({ list, openModalEvent, searchNoError, isChangeStatus = false }) => {
    const navigate = useNavigate();

    const [openItems, setOpenItems] = useState([]);

    const toggleItem = (index) => {
        if (openItems.includes(index)) {
            setOpenItems(openItems.filter((item) => item !== index));
        } else {
            setOpenItems([...openItems, index]);
        }
    };

    const getProposalStagesByProposal = async (item) => {
        try {
            const response = await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_PROPOSAL_STAGES}${item?.id}/${searchNoError}`);
            return response?.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }


    const data = (item) => {
        return (
            <>
                <div className={styles.informationItem}>
                    Proposta:
                    <strong>{item?.data?.proposalCode}</strong>
                </div>
            </>
        )
    }
    return (
        <ul className={styles.listContainer}>
            {list.map((item, index) => (
                <li key={index}>
                    <div className={styles.information}>
                        {openItems.includes(index) ? (
                            <div className={styles.iconAction}>
                                <RiIndeterminateCircleLine onClick={() => toggleItem(index)} />
                                {data(item)}
                            </div>

                        ) : (
                            <div className={styles.iconAction}>
                                <RiAddCircleLine onClick={() => toggleItem(index)} />
                                {data(item)}
                            </div>

                        )}
                        {openItems.includes(index) && (
                            <div className={styles.renderItem}>
                                <InteractiveList
                                    list={getProposalStagesByProposal(item)}
                                    openModalEvent={openModalEvent}
                                    isChangeStatus={isChangeStatus}
                                />
                            </div>
                        )}
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default InteractiveListProposal;