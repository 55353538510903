import moment from 'moment/moment';
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { checkJwt } from '../../../../helpers/Jwt-helper';
import { RoleContext } from '../../../hooks/HookUseContext';

const Workflow = ({ data, styles, renderIcon, navigate, openModalEvent }) => {

    const { roleValue } = useContext(RoleContext);
    let itens = data?.proposalStages;
    const [indice, setIndice] = useState();
    const itensPorPagina = 3;

    const proximaPagina = () => {
        setIndice(indice + 1);

    };

    const paginaAnterior = () => {
        setIndice(indice - 1);
    };

    const itensPagina = itens.slice(indice, indice + itensPorPagina);



    useEffect(() => {
        if (itens) {
            itens.forEach((item, index) => {
                if (itens.length === 3) {
                    setIndice(0)
                } else if (itens.length === (index + 1) && item.active) {
                    setIndice(index - 2)
                }
                else if (item.active) {
                    setIndice(Math.ceil((index + index) / 3));
                }
            })
        }
        return () => clearTimeout();
    }, [])

    return <>
        {indice === 0 ? <button disabled><IoIosArrowBack /></button> : (
            <button onClick={paginaAnterior}><IoIosArrowBack /></button>
        )}

        {itensPagina && itensPagina.map((work, index) => (
            <div key={work?.id?.stagesId} className={`${styles[work?.status?.code.toLowerCase()]}`}>
                <div className='tooltip'>
                    <p className={styles.icon}>
                        {renderIcon(work?.status?.code.toLowerCase())}
                    </p>
                    <span className={`tooltiptext ${work?.message?.description?.length >= 60 && 'tooltipTextLarge'}`}>{(work?.status?.code.toLowerCase() === 'error' || work?.status?.code.toLowerCase() === 'rejected' || work?.status?.code.toLowerCase() === 'blocked') ? work?.message?.description : work?.status?.description}</span>
                </div>
                <h5>{work?.stages?.description}</h5>
                {
                    work?.stageDate && work?.status?.code.toLowerCase() !== 'disabled' ? (
                        <p>
                            {work?.stageDate && moment(work?.stageDate).format('DD/MM/YYYY HH:mm:ss')}

                            {work?.status?.code.toLowerCase() === 'wait' ?
                                (
                                    <span className={styles.propries}>
                                        <AiOutlineEye className={` ${styles.disabled}`} />
                                    </span>
                                ) : (
                                    <span className={`${styles.propries} `} onClick={() => {
                                        checkJwt(navigate).then((response) => {
                                            if (response) {
                                                openModalEvent(data, work?.stages?.description, work?.stages?.code, work?.status);
                                            }
                                        })
                                    }}>
                                        {(
                                            (roleValue?.includes("SCORE_DATA_DETAILS_READ") && work?.stages?.code === "DOCUMENT_SCORE_DATA_CADASTRAL") ||
                                            (roleValue?.includes("SCORE_DETAILS_READ") && work?.stages?.code === "DOCUMENT_SCORE") ||
                                            (roleValue?.includes("INSPECTION_DETAILS_READ") && work?.stages?.code === "VEHICLE_INSPECTION") ||
                                            (roleValue?.includes("ADDRESS_VALIDATION_READ") && work?.stages?.code === "ADDRESS_VALIDATION") ||
                                            (roleValue?.includes("SENDING_EMAIL_READ") && work?.stages?.code === "SENDING_EMAIL") ||
                                            (roleValue?.includes("PLATE_DETAILS_READ") && work?.stages?.code === "VEHICLE_PLATE_CONSULT")) && <>
                                                <AiOutlineEye />
                                            </>}
                                        {
                                            (
                                                work?.stages?.code === "DIGITAL_SIGNATURE" ||
                                                work?.stages?.code === "INSURANCE_CANCEL" ||
                                                work?.stages?.code === "MANUAL_SIGNATURE" ||
                                                work?.stages?.code === "INSURANCE_CONTRACT")
                                            && <AiOutlineEye />
                                        }
                                    </span>
                                )
                            }
                        </p>
                    ) : ''
                }
            </div>
        ))}
        <button disabled={indice + itensPorPagina >= itens.length} onClick={proximaPagina}><IoIosArrowForward /></button>
    </>
}

export default Workflow