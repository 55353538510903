import { AiOutlineFileSearch } from "react-icons/ai"
import InputFilter from "../../../../form/InputFilter/InputFilter";
import { useContext, useEffect, useState } from "react";
import styles from './PartnerScreen.module.css';
import Pagination from "../../../../layout/pagination/Pagination";
import { checkJwt } from "../../../../../helpers/Jwt-helper";
import { useNavigate } from "react-router-dom";
import api from "../../../../../services/api";
import Loading from "../../../../layout/loading/Loading";
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ModalMessage from "../../modalMessage/ModalMessage";
import { RoleContext } from "../../../../hooks/HookUseContext";
import ResetButton from "../../resetButton/ResetButton";
import ModalPartnerInformation from "./modalPartnerInformation/ModalPartnerInformation";

const PartnerScreen = () => {
    const navigate = useNavigate();
    const [proposalCode, setProposalCode] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [dataObject, setDataObject] = useState();
    const [pageSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [modalOpenInformation, setModalOpenInformation] = useState(false);
    const [item, setItem] = useState(null);
    const { roleValue } = useContext(RoleContext);

    const openModalEvent = (message) => {
        setMessage(message);
        setModalOpen(true);
    }

    const openModalEventInformation = (item) => {
        setItem(item);
        setModalOpenInformation(true);
    }

    const getAllPartners = async (page) => {
        try {
            setLoading(true);
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_PARTNER_NOTIFIER}?page=${page}&size=${10}`,
                {
                    params: {
                        "proposalCode": proposalCode,
                    }
                }, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'

                },
            }).then((response) => {
                setDataObject(response?.data);
                setLoading(false);
            })
                .catch((error) => {

                    setLoading(false);
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    const putResetPartner = async (data, setLoading) => {
        try {
            setLoading(true);
            await api.put(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_PARTNER_NOTIFIER_RESET_ATTEMPTS}${data?.proposalCode}`
            ).then((response) => {
                const indexToUpdate = dataObject.content.findIndex(obj => obj.proposalCode === data?.proposalCode);

                if (indexToUpdate !== -1) {
                    const updatedContent = [...dataObject.content];
                    updatedContent[indexToUpdate] = response?.data;
                    setDataObject({ ...dataObject, content: updatedContent });
                }
                setLoading(false);
                toast.success("Tentativas resetado com sucesso");
            }).catch((error) => {
                setLoading(false);
                console.log(error)
                toast.error(error?.response?.data?.message || error?.response?.data);
            })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    const handleCurrentPage = (page) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                setCurrentPage(page);
                getAllPartners(page);
            }
        })
    }


    useEffect(() => {
        checkJwt(navigate).then((response) => {
            if (response) {
                getAllPartners(currentPage);

            }
        })
    }, [])

    const submit = (event) => {
        event?.preventDefault();
        checkJwt(navigate).then((response) => {
            if (response) {
                setCurrentPage(0);
                getAllPartners(0);
            }
        })

    }

    const handleReset = (data, setLoading) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                putResetPartner(data, setLoading);
            }
        })

    }
    return (
        <>
            {roleValue?.includes("INCIDENTS_PARTNER_NOTIFICATION_READ") && (
                <div className={styles.partnerContainer}>
                    {modalOpen === true && <ModalMessage setOpenModal={setModalOpen} message={message} />}
                    {modalOpenInformation === true && <ModalPartnerInformation setOpenModal={setModalOpenInformation} item={item} />}
                    <div className={`${styles.card} ${styles.column}`}  >
                        <h2>Filtro</h2>
                        <div className={styles.filter}>
                            <form onSubmit={submit}>
                                <InputFilter
                                    type="text"
                                    name={"proposalNumber"}
                                    text="Num. da proposta"
                                    required={false}
                                    maxLength={10}
                                    value={proposalCode}
                                    handleOnChange={(e) => setProposalCode(e.target.value)} />
                                <button >
                                    <AiOutlineFileSearch />
                                    Buscar
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className={`${styles.card} ${styles.column}`}  >
                        {loading && <Loading />}
                        {!loading && (
                            <>
                                <table className={styles.partnerToContainer}>
                                    <thead>
                                        <tr>
                                            <th>Processo</th>
                                            <th>Proposta</th>
                                            <th>Status</th>
                                            <th>Tentativas</th>
                                            <th>Mensagem</th>
                                            <th>Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataObject && dataObject?.content && dataObject?.content?.length === 0 && (
                                            <tr className={styles.noData}>
                                                <td>Não há dados</td>
                                            </tr>
                                        )}
                                        {dataObject && dataObject?.content && dataObject?.content?.length > 0 && (
                                            dataObject?.content.map((data, index) => (
                                                <tr key={data?.proposalCode || index} className={styles.division}>
                                                    <td>
                                                        <strong>Processo</strong>
                                                        {data?.processStepActivityNumber?.processStep?.description}
                                                    </td>
                                                    <td>
                                                        <strong>Proposta </strong>
                                                        {data?.proposalCode}
                                                    </td>
                                                    <td>
                                                        <strong>Status </strong>
                                                        {data?.status}
                                                    </td>
                                                    <td>
                                                        <strong>Tentativas</strong>
                                                        {data?.attempts}
                                                    </td>
                                                    <td onClick={() => openModalEvent(data?.message?.detail)}>
                                                        <div style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                            maxWidth: "400px",
                                                            cursor: "pointer"
                                                        }}>
                                                            <strong>Mensagem </strong>
                                                            {data?.message?.detail}
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "flex", 'gap': '1em' }}>
                                                        {roleValue?.includes("INCIDENTS_PARTNER_NOTIFICATION_RESET_ATTEMPTS_UPDATE") && (
                                                            <>
                                                                <ResetButton
                                                                    data={data}
                                                                    handleReset={handleReset}
                                                                />

                                                            </>
                                                        )}
                                                        <>
                                                            <button onClick={() => openModalEventInformation(data)}>Informações</button>
                                                        </>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                                <Pagination
                                    className={styles.pagination_bar}
                                    currentPage={currentPage === 0 ? 1 : currentPage + 1}
                                    totalCount={dataObject?.totalElements ? dataObject?.totalElements : []}
                                    pageSize={pageSize ? pageSize : 1}
                                    onPageChange={page => handleCurrentPage(page - 1)}
                                />
                            </>
                        )}

                    </div>
                </div>
            )}

        </>
    )
}

export default PartnerScreen